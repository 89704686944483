<template>
    <div class="EncomendaFesta">
        <div class="container">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="row">
                    <div class="col-12 mt-5 text-center titulo">
                        <h1>Corporativo</h1>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-6 offset-xl-3 mt-5">
                <div class="row">
                    <div class="col-12 form-group">
                        <p>
                            As encomendas devem ser feitas com pelo menos 3 dias
                            de antecedência. Para respostas referentes a
                            orçamentos, fazer com pelo menos 4 dias de
                            antecedência, via WhatsApp.
                        </p>

                        <h2 class="mt-5 mb-3">QUAL O MÍNIMO POR ENCOMENDA</h2>

                        <ul>
                            <li>
                                PARA BRIGADEIROS: o mínimo são 25 unidades por
                                sabor OU no caso de sabor único, o mínimo são 100
                                unidades.
                            </li>

                            <li>
                                PARA MINI TRUFFAS: o mínimo são 50 unidades por
                                sabor.
                            </li>

                            <li>
                                PARA MINI PÃES DE MEL: o mínimo são 100 unidades,
                                podendo haver variedade de sabores.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EncomendaCorporativo",
};
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";
.EncomendaFesta {
    @include background;

    .titulo {
        @include titulo;
    }

    h1 {
        text-transform: uppercase;
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 50px;
        line-height: 50px;
    }

    h2 {
        color: $marrom;
        font-family: $roboto;
        font-weight: bold;
        font-style: normal;
        font-size: 22px;
        line-height: 22px;
    }

    p,
    li {
        color: $marrom;
        font-family: $roboto;
        font-weight: 500;
        font-style: normal;
        font-size: 17px;
        line-height: 24px;
    }
}
@media (max-width: 767.98px) {
    .EncomendaFesta {
        padding: 50px 0 80px;
    }
}
</style>
